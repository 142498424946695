import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';

import { PremiumRoute } from '../components/router';

import { CuentaGrupalLayout } from '../layouts';
import { api } from '../services';

const CuentaGrupal = () => {
  const [data, setData] = useState({});
  const [plan, setPlan] = useState({});
  const [labels, setLabels] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);

  const { fetchGroupMembers } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      if (searchData) {
        setLoading(true);
        try {
          let response = null;
          response = await fetchGroupMembers(currentToken, currentLang);

          if (!ignore) {
            setData(response.users);
            setPlan(response.plan);
            setLabels(response.labels);
            console.debug('repuesta', response);
          }
        } catch (e) {
          setError(e.message);
          setLoading(false);
        } finally {
          console.debug('finalizo');
          setLoading(false);
          setSearchData(false);
        }
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [searchData]);

  return (
    <Router>
      <PremiumRoute
        path="/cuenta-grupal/"
        component={CuentaGrupalLayout}
        data={data}
        plan={plan}
        labels={labels}
        loading={loading}
        currentLang={currentLang}
        error={error}
        setLoading={setLoading}
        setSearchData={setSearchData}
      />
    </Router>
  );
};

export default CuentaGrupal;
